<template>
  <span>
    Default:
    {{featured}}
  </span>
</template>
<script>
  export default {
    name: 'DefaultCard',
    props: {
      featured: Object,
    },
    data () {
      return { }
    },
  }
</script>
