<template>
  <v-container>
    <v-row>
      <v-col md="12" class="pa-md-10 justify-center text-center">
        <v-card elevation="6" height="410" class="pa-0">
          <v-col md="6" class="d-flex justify-center align-center">
            text
          </v-col>
          <v-col class="text-right">
            IMG HERE
          </v-col>
        </v-card>
      </v-col>
      {{ streams[curTag] }}
    </v-row>
    <v-row>
      <v-col md="8">
        <v-row>
          <v-col v-if="curTag == 'axie'" md="12" class="pa-md-10">
            <AxieCard :featured="curGame" />
          </v-col>
          <v-col v-else-if="curTag && curGame" md="12" class="pa-md-10">
            <LolCard :featured="curGame" />
          </v-col>
          <v-col v-else md="12" class="pa-md-10">
            <p class="font-weight-bold text-h5">Live Match</p>
            <v-card elevation="6" height="" style="border: 9px solid #2a2e34; border-radius:25px">
              <v-row no-gutters>
                <v-col class="d-flex align-md-center justify-center flex-column pa-md-10 pa-3">
                  <v-avatar
                    :size="$vuetify.breakpoint.mdAndUp ? 100 : 48"
                    :style="$vuetify.breakpoint.mdAndUp ? 'margin: 35px; box-shadow:  0 0 0 15px hsl(0, 0%, 50%), 0 0 0 25px hsl(217, 9%, 28%), 0 0 0 30px hsl(218, 12%, 19%), 0 0 5px 32px hsl(19, 92%, 53%);' : 'margin: 35px'"
                  >
                    <v-img contain height="128" src="https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/Amumu.png" />
                  </v-avatar>
                  <span class="font-weight-bold text-md-h6">Team Liquid</span>
                  <span>1st</span>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center justify-center text-center">
                  <div class="dot-left">
                    <div />
                    <div />
                    <div />
                  </div>
                  <span class="pa-5">X</span>
                  <div class="dot-left flex-row-reverse">
                    <div />
                    <div />
                    <div />
                  </div>
                  <span class="text-md-h1 text-h6 font-weight-bold px-md-10">VS</span>
                  <div class="dot-right">
                    <div />
                    <div />
                    <div />
                  </div>
                  <span class="pa-5">X</span>
                  <div class="dot-right flex-row-reverse">
                    <div />
                    <div />
                    <div />
                  </div>
                </v-col>
                <v-col v-else class="d-flex align-center justify-center text-center">
                  <span class="text-md-h1 text-h5 font-weight-bold">VS</span>
                </v-col>
                <v-col class="d-flex align-md-center justify-center flex-column pa-md-10 pa-3">
                  <v-avatar
                    :size="$vuetify.breakpoint.mdAndUp ? 100 : 48"
                    :style="$vuetify.breakpoint.mdAndUp ? 'margin: 35px; box-shadow: 0 0 0 15px hsl(0, 0%, 50%), 0 0 0 25px hsl(217, 9%, 28%), 0 0 0 30px hsl(218, 12%, 19%), 0 0 5px 32px hsl(19, 92%, 53%);' : 'margin: 35px'"
                  >
                    <v-img contain height="128" src="https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/Amumu.png" />
                  </v-avatar>
                  <span class="font-weight-bold text-md-h6">Team Liquid</span>
                  <span>1st</span>
                </v-col>
              </v-row>
              <v-divider class="mx-10" />
              <v-row>
                <v-col>
                  <p />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" class="px-md-11">
            <p class="font-weight-bold text-h5 pl-md-0 pt-md-10">Games</p>
            <v-row no-gutters>
              <v-slide-group
                v-model="model"
                mandatory
                class="pa-0"
                center-active
                show-arrows="desktop"
              >
                <v-slide-item
                  v-for="n, i in games"
                  :key="i"
                  v-slot="{ active, toggle }"
                >
                  <!-- 'border: 1px solid transparent; border-image: linear-gradient(90deg, #f5611b 0%, #cf31d1 100%); border-image-slice: 1;' -->
                  <v-card
                    :style="active ? 'border: 1px solid #fff;' : undefined"
                    color="#2a2e34"
                    class="ma-2 pa-4 shadow__card"
                    height="143"
                    width="125"
                    @click="toggle"
                  >
                    <v-row
                      no-gutters
                      class="text-center"
                      @click="selectGame(n.text)"
                    >
                    <v-badge
          color="green"
          :content="n.n"
        >
                      <v-img max-height="90" max-width="90" :src="n.src" style="border-radius:25%" />
                    </v-badge>
                      <v-col class="pt-2">{{ n.text }}</v-col>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="py-5 mx-md-6" no-gutters>
          <v-col md="12">
            <p class="font-weight-bold text-h5">Past tournaments</p>
            <v-card>
              <past-tournament />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4" class="pa-md-10">
        <v-card class="pa-5 py-10 fill-height" width="423" color="#2a2e34" style="border-radius: 25px; filter: drop-shadow(0px 2px 13.5px rgba(0,0,0,0.36));">
          <v-col class="pa-0">
            <p class="font-weight-bold text-h5 my-0">Popular Users</p>
            <p class="font-weight-thin text-h6" style="color: #21cb00">Online <span class="b"><div /></span></p>
          </v-col>
          <v-row>
            <v-col v-for="stream in streams[curTag]" :key="stream.addr" md="4" class="d-flex flex-column mx-0">
              <users-card @click="selectUser" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapState,
  } from 'vuex'
  import PastTournament from '../../components/featured/gamestreams/PastTournament.vue'
  import UsersCard from '../../components/featured/gamestreams/UsersCard.vue'
  import AxieCard from '../../components/featured/live/axie.vue'
  import LolCard from '../../components/featured/live/lol.vue'
  export default {
    components: {
      PastTournament,
      UsersCard,
      LolCard,
      AxieCard,
    },
    data () {
      return {
        curTag: '',
        model: null,
        curGame: null,
        ggames: [
          { text: 'CS:GO', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'Fortnite', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'LOL', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'TFT', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'QWER', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: '1234', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'qweqw', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
          { text: 'asdasddsa', src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200' },
        ],
      }
    },
    computed: {
      ...mapState('platform', ['streams']),
      games () {
        var r = []
        for (var tag of Object.keys(this.streams)) {
          r.push({
            text: tag,
            n: Object.keys(this.streams[tag]).length || '0',
            src: 'https://img.utdstc.com/icon/c4e/1be/c4e1becb64d8233a6bd4b0df74b14a40051d10f1e68733ff2bbab3d09d544fa1:200',
          })
        }
        return r
      },
    },
    async mounted () {
      await this.getStreams()
      this.selectGame(Object.keys(this.streams)[0])
    },
    methods: {
      ...mapActions('platform', ['getStreams']),
      selectUser () {
        console.log('do sleect user!')
      },
      selectGame (game) {
        this.curTag = game
        this.curGame = this.streams[this.curTag][Object.keys(this.streams[this.curTag])[0]]
        console.log('do sleect game!')
      },
    },
  }
</script>

<style scoped>
  /* quando nao scopado fica igual do game portal */
  .b > div {
    width: 11px;
    height: 11px;
    border-radius: 5px;
    background-color: #21cb00;
    display: inline-flex;
  }

  .v-slide-group__prev {
    min-width: 10px;
    border-radius: 50%;
    position: absolute;
    background: #2a2e34;
    top: -40px;
    right: 38px;
  }

  .v-slide-group__next {
    min-width: 10px;
    border-radius: 50%;
    position: absolute;
    background: #2a2e34;
    top: -40px;
    right: 0px;
  }

  .shadow__card {
    /* border-radius: 25px; */
    filter: drop-shadow(0px 2px 13.5px rgba(0,0,0,0.36));
  }

  .dot-left, .dot-right {
    display : flex;
    justify-content: center;
    align-items : center;
  }

  .dot-left > div {
    width: 5px;
    height: 5px;
    background: #e15512;
    border-radius: 50%;
    margin : 3px;
  }

  .dot-right > div {
    width: 5px;
    height: 5px;
    background: #d031d1;
    border-radius: 50%;
    margin : 3px;
  }

  .dot-left > div:nth-child(1) {
    width: 13px;
    height: 13px;
  }

  .dot-right > div:nth-child(1) {
    width: 13px;
    height: 13px;
  }

  .dot-left > div:nth-child(2) {
    width: 9px;
    height: 9px;
  }

  .dot-right > div:nth-child(2) {
    width: 9px;
    height: 9px;
  }

</style>
