<template>
  <span>
    <v-card elevation="6" height="116" width="119" style="border-radius: 10px" color="#25282d">
      <v-col class="d-flex justify-center align-center fill-height">
        <v-img src="https://e7.pngegg.com/pngimages/257/279/png-clipart-european-league-of-legends-championship-series-counter-strike-global-offensive-dreamhack-eleague-major-boston-2018-storm-carnivoran-logo.png" height="64" width="64" contain />
      </v-col>
    </v-card>
    <v-card-title class="justify-center pb-0 pt-1">JOAO</v-card-title>
    <p class="text-center font-weight-bold" style="color: #9da3ac; font-size: 13px;">12 people</p>
  </span>
</template>

<script>
  export default {
    name: 'TopUsersCard',
    data () {
      return {}
    },
  }
</script>
