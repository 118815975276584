<template>
  <v-container>
  <v-row>
    <v-col cols="12" centered>
      {{ GameTime(featured.lastGameTime) }}
    </v-col>
  </v-row>
  <v-row dense justify="space-around">
    <v-col cols="6">
      <v-simple-table class="mb-1">
        <template #default class="">
          <p class="text-center font-weight-bold">TeamA</p>
          <tbody>
            <tr v-for="(player, a) in teamA" :key="a" @click="doSelect(player.summonerName,featured.region)">
              <td class="d-flex pa-0">
                <p class="d-block text-truncate pl-1" style="width:110px">{{ player.summonerName }}</p>
                <v-img
                  :src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/${revemoSpace(player.champion)}.png`"
                  :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/${revemoSpace(player.champion)}.png`"
                  max-width="48"
                  max-height="48"
                />{{ player.level }}</td>
              <td>
                <v-row>
                  <v-img
                    v-for="item,id in player.items"
                    :key="id"
                    :src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/item/${item.itemID}.png`"
                    :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/item/${item.itemID}.png`"
                    max-width="32"
                    max-height="32"
                  />
                </v-row>
                <v-row>
                  {{ player.scores.kills }}/{{ player.scores.deaths }}/{{ player.scores.assists }} - M{{ player.scores.creepScore }} - W{{ parseInt(player.scores.wardScore) }}
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col cols="6">
      <v-simple-table>
        <template #default class="">
          <p class="text-center font-weight-bold">TeamB</p>
          <tbody>
            <tr v-for="(player, a) in teamB" :key="a" @click="doSelect(player.summonerName,featured.region)">
              <td class="d-flex pa-0">
                <p class="d-block text-truncate pl-1" style="width:110px">{{ player.summonerName }}</p>
                <v-img
                  :src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/${revemoSpace(player.champion)}.png`"
                  :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/champion/${revemoSpace(player.champion)}.png`"
                  max-width="48"
                  max-height="48"
                />{{ player.level }}</td>
              <td>
                <v-row>
                  <v-img
                    v-for="item,id in player.items"
                    :key="id"
                    :src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/item/${item.itemID}.png`"
                    :lazy-src="`https://ddragon.leagueoflegends.com/cdn/11.11.1/img/item/${item.itemID}.png`"
                    max-width="32"
                    max-height="32"
                  />
                </v-row>
                <v-row>
                  {{ player.scores.kills }}/{{ player.scores.deaths }}/{{ player.scores.assists }} - M{{ player.scores.creepScore }} - W{{ parseInt(player.scores.wardScore) }}
                </v-row>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'LOLCard',
    props: {
      featured: Object,
    },
    data () {
      return {
        teamA: [],
        teamB: [],
      }
    },
    mounted () {
      this.getTeams()
    },
    methods: {
      GameTime (time) {
        var t = parseInt(time / 60) + ':' + parseInt(time % 60)
        return t
      },
      revemoSpace (str) {
        if (str === 'LeBlanc') return 'Leblanc'
        else if (str === "Vel'Koz") return 'Velkoz'
        else if (str === "Kog'Maw") return 'KogMaw'
        else if (str === 'Nunu e Willump') return 'Nunu'
        console.log(str)
        return str.replace(' ', '')
      },
      doSelect (alias, region) {
        console.log('z')
        this.$emit('onselect', alias, region)
      },
      getTeams () {
        for (var p of this.featured.players) {
          if (p.team === 0) this.teamA.push(p)
          else if (p.team === 1) this.teamB.push(p)
        }
        console.log(this.teamA)
      },
    },
  }
</script>
